/* RESPONSIBLE TEAM: team-help-desk-experience */
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type BlockList, type Block } from '@intercom/interblocks.ts';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';

type Translations = {
  [languageCode: string]: string;
};

type ListTranslations = {
  [languageCode: string]: string[];
};

const TRANSLATABLE_TYPES = [
  RenderableType.AdminComment,
  RenderableType.UserComment,
  RenderableType.UserEmailComment,
  RenderableType.BotComment,
  RenderableType.FinAnswer,
  RenderableType.Chat,
  RenderableType.QuickReplyResponse,
];

const TRANSLATABLE_LIST_TYPES = ['orderedList', 'unorderedList'];
const TRANSLATABLE_TEXT_TYPES = [
  'paragraph',
  'heading',
  'subheading',
  'subheading2',
  'subheading3',
  'subheading4',
];

interface RenderablePartWithBlocks {
  renderableData: { blocks?: BlockWithTranslations[] };
}

export interface RenderablePartWithTranslations {
  renderableData: QuickReplyWithTranslations;
}

export type QuickReplyWithTranslations = {
  translations?: Translations;
  original?: string;
  text: string;
};

type BlockWithTranslations = Block & {
  translations?: Translations;
  original?: string;
};

export function translatedPartExists(partGroup: PartGroup): boolean {
  return !!partGroup.parts.any((part) => {
    if (!TRANSLATABLE_TYPES.includes(part.renderableType)) {
      return false;
    }

    if (part.renderableType === RenderableType.QuickReplyResponse) {
      let renderablePartWithTranslations = part as unknown as RenderablePartWithTranslations;
      return translationExistsInQuickReplyResponse(renderablePartWithTranslations);
    }

    let renderablePartWithBlocks = part as unknown as RenderablePartWithBlocks;
    return translationExists(renderablePartWithBlocks.renderableData?.blocks || []);
  });
}

function getLanguageCodeFromPartGroup(partGroup: PartGroup, original = false): string | undefined {
  for (let part of partGroup.parts) {
    if (part.renderableType === RenderableType.QuickReplyResponse) {
      let renderablePartWithTranslations = part as unknown as RenderablePartWithTranslations;

      if (original) {
        return renderablePartWithTranslations.renderableData.original;
      } else {
        let translatedLanguage = getTranslationLanguageFromQuickReplyResponse(
          renderablePartWithTranslations,
        );
        return translatedLanguage;
      }
    }

    let renderablePartWithBlocks = part as unknown as RenderablePartWithBlocks;
    let blocks = renderablePartWithBlocks.renderableData?.blocks || [];
    if (translationExists(blocks) || original) {
      for (let block of blocks) {
        if (original) {
          let originalLanguage = getOriginalLanguageFromBlock(block);
          if (originalLanguage) {
            return originalLanguage;
          }
        } else {
          let translationLanguage = getTranslationLanguageFromBlock(block);
          if (translationLanguage) {
            return translationLanguage;
          }
        }
      }
    }
  }

  return undefined;
}

function getOriginalLanguageFromBlock(block: Block): string | undefined {
  if ('original' in block) {
    return block.original as string;
  }
  return undefined;
}

function getTranslationLanguageFromBlock(block: Block): string | undefined {
  if ('translations' in block && 'original' in block) {
    let languages = Object.keys(block.translations as Translations);
    let translated_languages = languages.filter((lang) => lang !== block.original);
    if (translated_languages.length > 0) {
      return translated_languages[0];
    }
  }
  return undefined;
}

function getTranslationLanguageFromQuickReplyResponse(
  part: RenderablePartWithTranslations,
): string | undefined {
  if ('translations' in part.renderableData && 'original' in part.renderableData) {
    let translations = part.renderableData.translations || {};
    let languages = Object.keys(translations);
    let translated_languages = languages.filter((lang) => lang !== part.renderableData.original);
    if (translated_languages.length > 0) {
      return translated_languages[0];
    }
  }

  return undefined;
}
export function getLanguageFromPartGroup(partGroup: PartGroup, locale: string, original = false) {
  let languageCode = getLanguageCodeFromPartGroup(partGroup, original);
  if (languageCode) {
    return getLanguageFromCode(languageCode, locale);
  }
  return languageCode;
}

function getLanguageFromCode(languageCode: string, locale: string): string {
  try {
    let languageNames = new Intl.DisplayNames([locale], { type: 'language' });
    return languageNames.of(languageCode) || '';
  } catch (e) {
    // Fallback in case the language code is invalid or not supported
    return languageCode;
  }
}

function translationExists(blocks: BlockList): boolean {
  if (!blocks) {
    return false;
  }
  return blocks.any((block) => {
    if ('translations' in block && 'original' in block) {
      return Object.keys(block.translations as Translations).length > 1;
    }
    return false;
  });
}

function translationExistsInQuickReplyResponse(part: RenderablePartWithTranslations): boolean {
  return !!(
    part.renderableData.translations && Object.keys(part.renderableData.translations).length > 0
  );
}

export function translateQuickReplyResponse(
  renderablePart: QuickReplyWithTranslations,
  toTranslate: boolean,
): string {
  if (!toTranslate) {
    return renderablePart.text;
  }

  let translations = renderablePart.translations || {};
  let languages = Object.keys(translations);

  if (languages.length <= 1) {
    return renderablePart.text;
  }

  let translationLang = languages.find((lang) => lang !== renderablePart.original);

  if (translationLang) {
    return translations[translationLang] || renderablePart.text;
  }

  return renderablePart.text;
}

export function translateBlocks(blocks: BlockList, toTranslate: boolean): BlockList {
  blocks = blocks.map((block) => {
    return translateBlock(block, toTranslate);
  });
  return blocks;
}

export function translateBlock(block: Block, toTranslate: boolean, lang?: string): Block {
  if (!('translations' in block && 'original' in block)) {
    return block;
  }

  if (toTranslate) {
    lang = lang || getTranslationLanguageFromBlock(block);
  } else {
    lang = block.original as string;
  }

  if (!lang) {
    return block;
  }

  let translations = block.translations as Translations | ListTranslations;
  let translation = translations[lang];

  if (!translation) {
    return block;
  }

  if (isTextType(block) && 'text' in block) {
    block.text = translation as string;
  } else if (isHtmlType(block) && 'content' in block) {
    block.content = translation as string;
  } else if (isListType(block) && 'items' in block) {
    block.items = translation as string[];
  }
  return block;
}

function isTextType(block: Block): boolean {
  return TRANSLATABLE_TEXT_TYPES.includes(block.type);
}

function isHtmlType(block: Block): boolean {
  return block.type === 'html';
}

function isListType(block: Block): boolean {
  return TRANSLATABLE_LIST_TYPES.includes(block.type);
}

export function isTranslatingPartGroup(partGroup: PartGroup): boolean {
  let languageCode = getLanguageCodeFromPartGroup(partGroup, true);
  return !!languageCode && !translatedPartExists(partGroup);
}
