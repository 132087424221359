/* import __COLOCATED_TEMPLATE__ from './user-email-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type UserEmailComment from 'embercom/objects/inbox/renderable/user-email-comment';
import type Conversation from 'embercom/objects/inbox/conversation';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import type InboxState from 'embercom/services/inbox-state';
import { tracked } from '@glimmer/tracking';
import {
  INBOUND_EMAIL_SUPPORTED_TAGS,
  INBOUND_EMAIL_SUPPORTED_ATTRIBUTES,
} from 'embercom/lib/channels/constants';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';
import {
  getLanguageFromPartGroup,
  isTranslatingPartGroup,
  translatedPartExists,
} from '../helpers/translations';

interface UserRenderablePart extends RenderablePart {
  renderableData: UserEmailComment;
}

interface UserPartGroup extends PartGroup {
  parts: Array<UserRenderablePart>;
}

interface Args {
  partGroup: UserPartGroup;
  conversation: Conversation;
  isPreviewPanel?: boolean;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamUserEmailContentComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;

  @tracked showOriginalMessageToggle = false;

  @tracked
  supportedTags = INBOUND_EMAIL_SUPPORTED_TAGS;

  supportedAttributes = INBOUND_EMAIL_SUPPORTED_ATTRIBUTES;
  @tracked isManagingParticipants = false;

  get user(): UserSummary {
    return this.args.partGroup.parts[0].renderableData.userSummary;
  }

  get bccSenderWarning(): string {
    return this.intl.t('channels.conversation-stream.email-bcc-sender-warning');
  }

  get participantsData() {
    return {
      removedParticipantIds: [],
      newParticipantEmails: [],
      newParticipantIds: [this.user.id],
    };
  }

  get autoTranslationEnabled() {
    return this.conversationTranslationSettings.autoTranslationEnabledForConversation(
      this.args.conversation?.id,
    );
  }

  get showOriginalMessage(): boolean {
    if (!this.autoTranslationEnabled) {
      return true;
    }
    return this.showOriginalMessageToggle;
  }

  get showTranslatedMessage() {
    return !this.showOriginalMessage;
  }

  get translatedPartExists() {
    return translatedPartExists(this.args.partGroup);
  }

  get isTranslating() {
    return isTranslatingPartGroup(this.args.partGroup);
  }

  get originalLanguage() {
    return getLanguageFromPartGroup(this.args.partGroup, this.intl.primaryLocale, true) || '';
  }

  get showTranslationToggle() {
    return this.autoTranslationEnabled && (this.translatedPartExists || this.isTranslating);
  }

  @action toggleTranslation() {
    this.showOriginalMessageToggle = !this.showOriginalMessageToggle;
  }

  @action
  addExternalSenderAsParticipant() {
    this.inboxState.updateConversationParticipants(this.args.conversation, this.participantsData);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_external_sender_as_participant',
      section: 'user_email_bubble',
      place: 'inbox',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::UserEmailContent': typeof ConversationStreamUserEmailContentComponent;
    'inbox2/conversation-stream/part-groups/user-email-content': typeof ConversationStreamUserEmailContentComponent;
  }
}
